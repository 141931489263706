import { useDispatch, useSelector } from 'react-redux'

import { BedsFilterContainer, BedsFilterTriggerContainer, BedOption, BedOptionsContainer } from './BedsFilter.style'
import { ContainerTitle } from '../Containers/Containers.style'
import { searchActionReducer } from '../../../../store/search/searchSlice'

const BedsFilter = ({ refetchListings }) => {
  const dispatch = useDispatch()

  const { beds } = useSelector((state) => state.search)

  const minBeds = beds?.length > 0 && Math.min(...beds)
  const maxBeds = beds?.length > 0 && Math.max(...beds)

  const zeroBedsSelected = beds?.includes(0)
  const oneBedSelected = beds?.includes(1)
  const twoBedsSelected = beds?.includes(2)
  const threeBedsSelected = beds?.includes(3)
  const fourBedsSelected = beds?.includes(4)

  const setBeds = (payload) => dispatch(searchActionReducer.setBeds(payload))

  const handleZeroOnClick = () => {
    let bedsArray = []
    if (!zeroBedsSelected && fourBedsSelected) bedsArray = []
    else if (!zeroBedsSelected && threeBedsSelected) bedsArray = [0, 1, 2, 3]
    else if (!zeroBedsSelected && twoBedsSelected) bedsArray = [0, 1, 2]
    else if (!zeroBedsSelected && oneBedSelected) bedsArray = [0, 1]
    else if (!zeroBedsSelected) bedsArray = [0]
    else bedsArray = beds?.filter((option) => option !== 0)
    setBeds(bedsArray)
    refetchListings({ beds: bedsArray }, 'BedsFilter.handleZeroOnClick')
  }

  const handleOneOnClick = () => {
    let bedsArray = []
    if (oneBedSelected && !(maxBeds === 1 || minBeds === 1)) bedsArray = [1]
    else if (!oneBedSelected && fourBedsSelected) bedsArray = [1, 2, 3, 4]
    else if (!oneBedSelected && threeBedsSelected) bedsArray = [1, 2, 3]
    else if (!oneBedSelected && twoBedsSelected) bedsArray = [1, 2]
    else if (!oneBedSelected && zeroBedsSelected) bedsArray = [0, 1]
    else if (!oneBedSelected) bedsArray = [1]
    else bedsArray = beds?.filter((option) => option !== 1)
    setBeds(bedsArray)
    refetchListings({ beds: bedsArray }, 'BedsFilter.handleOneOnClick')
  }

  const handleTwoOnClick = () => {
    let bedsArray = []

    if (twoBedsSelected && !(maxBeds === 2 || minBeds === 2)) bedsArray = [2]
    else if (!twoBedsSelected && fourBedsSelected) bedsArray = [2, 3, 4]
    else if (!twoBedsSelected && zeroBedsSelected) bedsArray = [0, 1, 2]
    else if (!twoBedsSelected && threeBedsSelected) bedsArray = [2, 3]
    else if (!twoBedsSelected && oneBedSelected) bedsArray = [1, 2]
    else if (!twoBedsSelected) bedsArray = [2]
    else bedsArray = beds?.filter((option) => option !== 2)
    setBeds(bedsArray)
    refetchListings({ beds: bedsArray }, 'BedsFilter.handleTwoOnClick')
  }

  const handleThreeOnClick = () => {
    let bedsArray = []

    if (threeBedsSelected && !(maxBeds === 3 || minBeds === 3)) bedsArray = [3]
    else if (!threeBedsSelected && zeroBedsSelected) bedsArray = [0, 1, 2, 3]
    else if (!threeBedsSelected && oneBedSelected) bedsArray = [1, 2, 3]
    else if (!threeBedsSelected && twoBedsSelected) bedsArray = [2, 3]
    else if (!threeBedsSelected && fourBedsSelected) bedsArray = [3, 4]
    else if (!threeBedsSelected) bedsArray = [3]
    else bedsArray = beds?.filter((option) => option !== 3)
    setBeds(bedsArray)
    refetchListings({ beds: bedsArray }, 'BedsFilter.handleThreeOnClick')
  }

  const handleFourOnClick = () => {
    let bedsArray = []

    if (!fourBedsSelected && zeroBedsSelected) bedsArray = []
    else if (!fourBedsSelected && oneBedSelected) bedsArray = [1, 2, 3, 4]
    else if (!fourBedsSelected && twoBedsSelected) bedsArray = [2, 3, 4]
    else if (!fourBedsSelected && threeBedsSelected) bedsArray = [3, 4]
    else if (!fourBedsSelected) bedsArray = [4]
    else bedsArray = beds?.filter((option) => option !== 4)
    setBeds(bedsArray)
    refetchListings({ beds: bedsArray }, 'BedsFilter.handleFourOnClick')
  }

  return (
    <BedsFilterContainer>
      <BedsFilterTriggerContainer>
        <ContainerTitle>Beds</ContainerTitle>
        <BedOptionsContainer>
          <BedOption selected={zeroBedsSelected} onClick={handleZeroOnClick}>
            Studio
          </BedOption>
          <BedOption selected={oneBedSelected} onClick={handleOneOnClick}>
            1
          </BedOption>
          <BedOption selected={twoBedsSelected} onClick={handleTwoOnClick}>
            2
          </BedOption>
          <BedOption selected={threeBedsSelected} onClick={handleThreeOnClick}>
            3
          </BedOption>
          <BedOption selected={fourBedsSelected} onClick={handleFourOnClick}>
            4+
          </BedOption>
        </BedOptionsContainer>
      </BedsFilterTriggerContainer>
    </BedsFilterContainer>
  )
}

export default BedsFilter
