import React from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { searchActionReducer } from '../../../../store/search/searchSlice'
import { ListingTypeFilterContainer, RadioButton } from './ListingTypeFilter.style'

export const ListingTypeFilter = () => {
  const dispatch = useDispatch()

  const { type } = useSelector((state) => state.search)

  const setType = (payload) => dispatch(searchActionReducer.setType(payload))
  const resetMaxPrice = () => dispatch(searchActionReducer.setMaxPrice(null))
  const resetMinPrice = () => dispatch(searchActionReducer.setMaxPrice(null))
  const resetMaxPriceInput = () => dispatch(searchActionReducer.setMaxPriceInput(''))
  const resetMinPriceInput = () => dispatch(searchActionReducer.setMinPriceInput(''))
  const resetPriceContainer = () => dispatch(searchActionReducer.setPriceContainer('Any'))

  const handleOnClick = () => {
    const value = type === 'FOR_SALE' ? 'FOR_RENT' : 'FOR_SALE'
    resetMaxPrice()
    resetMinPrice()
    resetMaxPriceInput()
    resetMinPriceInput()
    resetPriceContainer()
    setType(value)
  }

  return (
    <ListingTypeFilterContainer>
      <RadioButton selected={type === 'FOR_SALE'} onClick={handleOnClick}>
        BUY A HOME
      </RadioButton>
      <RadioButton selected={type === 'FOR_RENT'} onClick={handleOnClick}>
        RENT A HOME
      </RadioButton>
    </ListingTypeFilterContainer>
  )
}

export default React.memo(ListingTypeFilter)
