import { useEffect } from 'react'

const useOutsideClick = (ref, callback, triggers) => {
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (!ref?.current) return
      if (ref?.current?.contains(event?.target)) return
      callback()
    }
    document.addEventListener('click', handleClickOutside)

    return () => {
      document.removeEventListener('click', handleClickOutside)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ref, callback, ...triggers])
}

export default useOutsideClick
