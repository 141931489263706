import { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder'
import FavoriteIcon from '@mui/icons-material/Favorite'
import { useSelector, useDispatch } from 'react-redux'
import { Loader } from 'semantic-ui-react'
import useAxios from 'axios-hooks'
import ReactGA from 'react-ga4'

import { BED_ICON, BATH_ICON, SQURE_FOOT_ICON } from '../../../../../constants/image.const'
import { CONSUMER_SAVED_LISTINGS } from '../../../../../constants/api'
import { ReactComponent as LOCATIN_ICON } from '../../../../../assets/images/marker_location.svg'
import { selectionsActionReducer } from '../../../../../store/selections/selectionsSlice'
import { modalsActionReducer } from '../../../../../store/modals/modalsSlice'
import { pageActionReducer } from '../../../../../store/page/pageSlice'
import FeaturedIcon from './FeaturedIcon'
import UnderConstructionIcon from './UnderConstructionIcon'
import Images from './Images'
import {
  FavoriteContainer,
  Icon,
  IconImage,
  IconsContainer,
  IsFeaturedContainer,
  ItemContainer,
  ItemDataContainer,
  ItemLowerHalfContainer,
  ItemSubtitle,
  MapIcon,
  PriceElement,
  TagContainers,
  TextElement,
} from './Item.style'
import clickEvents from '../../../../../services/analytics.service'
import { mapActionReducer } from '../../../../../store/map/mapSlice'

const Item = ({ getFavorites, listing, map, savedListing }) => {
  const dispatch = useDispatch()

  const { user } = useSelector((state) => state.auth)

  const setBuilding = (payload) => dispatch(selectionsActionReducer.setBuilding(payload))
  const openAuthModal = () => dispatch(modalsActionReducer.setAuthModalIsOpen(true))
  const setCurrentMobileView = (payload) => dispatch(pageActionReducer?.setSearchPage(payload))
  const setHoveredNeighborhood = (payload) => dispatch(mapActionReducer.setHoveredNeighborhood(payload))

  const [favoriteIsLoading, setFavoriteIsLoading] = useState(false)
  const [isFavorited, setIsFavorited] = useState(!!savedListing?.uuid)

  const userIsLoggedIn = !!user?.user

  const currencyFormatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumSignificantDigits: 1,
  })

  const numberFormatter = new Intl.NumberFormat('en-US', {
    minimumSignificantDigits: 1,
  })

  const handleShowOnMapOnClick = (event) => {
    event.preventDefault()
    event.stopPropagation()
    const buildingUuid = listing?.building?.uuid
    const center = [listing?.building?.coordinates?.[1], listing?.building?.coordinates?.[0]]

    setBuilding(buildingUuid)

    if (window.innerWidth <= 768) setCurrentMobileView('map')
    if (map?.getBounds()?.contains(center) && map.getZoom() === 18) return
    if (map?.getBounds()?.contains(center) && map.getZoom() <= 16)
      return map?.flyTo(center, 18, {
        duration: 2.0,
      })
    map?.flyTo(center, 18, {
      duration: 1,
    })

    ReactGA.event({
      category: 'action',
      action: 'search-page-listing-card-show-on-map-clicked',
    })
    clickEvents.SHOW_ON_MAP()
  }

  const [, addFavorite] = useAxios(
    {
      url: CONSUMER_SAVED_LISTINGS,
      method: 'POST',
      data: {
        listing: listing?.uuid,
      },
    },
    { manual: true },
  )

  const [, removeFavorite] = useAxios(
    {
      url: `${CONSUMER_SAVED_LISTINGS}${savedListing?.uuid}/`,
      method: 'DELETE',
    },
    { manual: true },
  )

  const handleItemOnClick = () => {
    ReactGA.event({
      category: 'action',
      action: 'search-page-listing-card-clicked',
    })
    clickEvents.LISTING_CARD()
  }

  const handleFavoriteOnClick = (event) => {
    event.stopPropagation()
    event.preventDefault()
    if (!user) return openAuthModal()
    if (favoriteIsLoading) return
    setFavoriteIsLoading(true)
    if (isFavorited)
      removeFavorite().then(() => {
        setFavoriteIsLoading(false)
        setIsFavorited(false)
        getFavorites(CONSUMER_SAVED_LISTINGS).then(() => setFavoriteIsLoading(false))
      })
    else
      addFavorite().then((rs) => {
        setFavoriteIsLoading(false)
        setIsFavorited(true)
        getFavorites(CONSUMER_SAVED_LISTINGS).then(() => setFavoriteIsLoading(false))
      })
  }

  const handleOnMouseEnter = () => setHoveredNeighborhood(listing?.building?.neighborhood)

  const handleOnMouseLeave = () => setHoveredNeighborhood(null)

  useEffect(() => setIsFavorited(!!savedListing?.uuid), [savedListing])

  return (
    <ItemContainer onMouseEnter={handleOnMouseEnter} onMouseLeave={handleOnMouseLeave}>
      <Link to={listing?.url} onClick={handleItemOnClick}>
        <TagContainers>
          {listing?.building?.isANewDevelopment && (
            <IsFeaturedContainer>
              {/* <UnderConstructionIcon /> */}
              Under Construction
            </IsFeaturedContainer>
          )}
          {listing?.isFeatured && (
            <IsFeaturedContainer>
              <FeaturedIcon />
              Featured
            </IsFeaturedContainer>
          )}
        </TagContainers>
        <FavoriteContainer onClick={handleFavoriteOnClick} disabled={favoriteIsLoading}>
          {favoriteIsLoading ? (
            <Loader size="mini" inline="centered" active color="white" />
          ) : userIsLoggedIn && isFavorited ? (
            <FavoriteIcon fontSize="small" />
          ) : (
            <FavoriteBorderIcon fontSize="small" />
          )}
        </FavoriteContainer>
        <Images listing={listing} />
        <ItemLowerHalfContainer>
          <ItemDataContainer>
            <TextElement>
              <ItemSubtitle>{listing?.building?.name}</ItemSubtitle>
              <span>{listing?.address}</span>
              <span>Unit {listing?.unit?.number}</span>
            </TextElement>
            <PriceElement>{currencyFormatter.format(listing?.price)}</PriceElement>
          </ItemDataContainer>
          <IconsContainer>
            <Icon>
              <IconImage src={BED_ICON} alt="bed-icon" />
              {listing?.unit?.numberOfBedrooms} Beds
            </Icon>
            <Icon>
              <IconImage src={BATH_ICON} alt="bath-icon" />
              {listing?.unit?.numberOfBathrooms} Baths
            </Icon>
            <Icon>
              <IconImage src={SQURE_FOOT_ICON} alt="square-foot-icon" />
              {numberFormatter.format(listing?.unit?.squareFootage)} ft²
            </Icon>
            <MapIcon onClick={handleShowOnMapOnClick}>
              <LOCATIN_ICON />
              <span>Map</span>
            </MapIcon>
          </IconsContainer>
        </ItemLowerHalfContainer>
      </Link>
    </ItemContainer>
  )
}

export default Item
